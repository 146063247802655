(function () {
  var SEARCH_CRITERIA_DIV_CLASS_NAME = ".filter-by";
  var NO_SEARCH_RESULT_DIV_CLASS_NAME = ".no-record-found";

  var SPINNER_CLASS_NAME = ".spinner-border";
  var MORE_EVENT_LOADING = ".more-events-loading>.spinner-border";

  var MORE_EVENTS_BUTTON_CLIENT_ID = "moreEventsButton";
  var SEARCH_BUTTON_CLIENT_ID = "searchEventsButton";
  var CLEAR_SEARCH_FILTER_BUTTON_CLIENT_ID = "clearFilterButton";
  var EVENT_RESULTS_CLIENT_ID = "eventResultsGrid";

  var numOfEventCards = 0;
  var filteredCount = 0;
  var shuffleInstance = null;
  var isSearchCompleted = false;

  function sortByDate(element) {
    return element.getAttribute("data-date-started");
  }

  // filter each item
  var SortOptions = {
    reverse: false,
    by: sortByDate,
  };

  function showElement(element, displayCSSClass) {
    if (element && displayCSSClass) {
      element.style.display = displayCSSClass;
    }
  }

  function hideElement(element) {
    if (element) {
      element.style.display = "none";
    }
  }

  function showLoadingMoreEventsIndicator() {
    showElement(document.querySelector(MORE_EVENT_LOADING), "block");
  }

  function hideLoadingMoreEventsIndicator() {
    hideElement(document.querySelector(MORE_EVENT_LOADING));
  }

  function showLoadingIndicator() {
    showElement(document.querySelector(SPINNER_CLASS_NAME), "inline-block");
  }

  function hideLoadingIndicator() {
    hideElement(document.querySelector(SPINNER_CLASS_NAME));
  }

  function showMoreEventsButton() {
    showElement(
      document.getElementById(MORE_EVENTS_BUTTON_CLIENT_ID),
      "inline-flex"
    );
  }

  function hideMoreEventsButton() {
    hideElement(document.getElementById(MORE_EVENTS_BUTTON_CLIENT_ID));
  }

  function showSearchCriteriaDiv() {
    showElement(document.querySelector(SEARCH_CRITERIA_DIV_CLASS_NAME), "flex");
    enableClearFilterButton();
  }

  function hideSearchCriteriaDiv() {
    hideElement(document.querySelector(SEARCH_CRITERIA_DIV_CLASS_NAME));
    clearSearchCriteria();
  }

  function showNoRecordFoundDiv() {
    showElement(
      document.querySelector(NO_SEARCH_RESULT_DIV_CLASS_NAME),
      "block"
    );
    var filteredPrimaryCategory = document.getElementById(
      "filteredPrimaryCategoryName"
    );
    if (filteredPrimaryCategory) {
      filteredPrimaryCategory.textContent = filters.primaryCategory;
    }
    enableClearFilterButton();
  }

  function showNoRecordDiv(query, spell) {
    if(!query){
      query = $('button.btn.btn-link.current').text();
    }
    document.getElementById("searchText").style.display = "none";
    document.getElementById("noResultDiv").style.display = "inline";
    if(spell){
      $('#spellSearch').html('<b>'+spell.text+'</b>');
      document.getElementById("noResultSuggestion").style.display = "inherit";
    }else{
      document.getElementById("noResultSuggestion").style.display = "none";
    }
    $("#noResultDivMessage").html('No results found for <strong>"'+query+'"</strong>');
    var filteredPrimaryCategory = document.getElementById(
      "filteredPrimaryCategoryName"
    );
    if (filteredPrimaryCategory) {
      filteredPrimaryCategory.textContent = filters.primaryCategory;
    }
  }

  function showRecordDiv(start, end, total, query){
    document.getElementById("searchText").style.display = "inline";
    if(!query || query=='!showall'){
      $("#searchText").html('<p style="padding-top:15px;">Showing 1 - '+end+' of '+total+'</p>');
    }else{
      $("#searchText").html('<p style="padding-top:15px;">Showing 1 - '+end+' of '+total+' for <strong>'+query+'</strong></p>')
    }
  }

  function hideNoRecordFoundDiv() {
    // hideElement(document.querySelector(NO_SEARCH_RESULT_DIV_CLASS_NAME));
    document.getElementById("noResultDiv").style.display = "none";
  }

  function enableElement(element) {
    if (element) {
      element.removeAttribute("disabled");
    }
  }

  function disableElement(element) {
    if (element) {
      element.setAttribute("disabled", "disabled");
    }
  }

  function enableSearchButton() {
    enableElement(document.getElementById(SEARCH_BUTTON_CLIENT_ID));
  }

  function disableSearchButton() {
    disableElement(document.getElementById(SEARCH_BUTTON_CLIENT_ID));
  }

  function disableClearFilterButton() {
    disableElement(
      document.getElementById(CLEAR_SEARCH_FILTER_BUTTON_CLIENT_ID)
    );
  }

  function enableClearFilterButton() {
    enableElement(
      document.getElementById(CLEAR_SEARCH_FILTER_BUTTON_CLIENT_ID)
    );
  }

  function enableMoreEventsButton() {
    enableElement(document.getElementById(MORE_EVENTS_BUTTON_CLIENT_ID));
    showMoreEventsButton();
  }

  function disableMoreEventsButton() {
    disableElement(document.getElementById(MORE_EVENTS_BUTTON_CLIENT_ID));
  }

  function clearSearchCriteria() {
    // clear search criteria
    document.getElementById("filterByTitleWords").innerText = "";
    document.querySelector(".filter__search").value = "";
  }

  function clickAllCategoryButton() {
    document.getElementById("allCategoryButton").click();
  }

  // get valid date ranges in Moment object
  function getValidDateRanges(dateRanges) {
    var validDateRanges = [];

    for (var i = 0; i < dateRanges.length; i++) {
      var dateRangeString = dateRanges[i];
      if (dateRangeString.trim().length) {
        var dateArray = dateRangeString.split(";");

        var mStartDate, mEndDate;
        var startDateString = dateArray[0];
        if (startDateString) {
          mStartDate = moment(startDateString, false);
        }

        var endDateString = dateArray[1];
        if (endDateString) {
          mEndDate = moment(endDateString, false);
        }

        // start date and end date must always filled for an event
        if (mStartDate.isValid() && mEndDate.isValid()) {
          validDateRanges.push({ startDate: mStartDate, endDate: mEndDate });
        } else {
          console.error("Invalid start date: " + startDateString);
          console.error("Invalid end date: " + endDateString);
        }
      }
    }

    return validDateRanges;
  }

  /*
   * Return True if start end date covers any given date rage, otherwise return false
   * Note: date objects are Moment obj
   */
  function checkEventAvailability(
    dateRangesObject,
    startDateObject,
    endDateObject
  ) {
    for (var i = 0; i < dateRangesObject.length; i++) {
      var dateRange = dateRangesObject[i];

      // dateRange.Start >= startDateObject AND dateRange.Start <= endDateObject
      if (
        dateRange.startDate
          .startOf("day")
          .isSameOrAfter(startDateObject.startOf("day")) &&
        dateRange.startDate
          .startOf("day")
          .isSameOrBefore(endDateObject.startOf("day"))
      ) {
        return true;
      }

      // dateRange.End >= startDateObject AND dateRange.End <= endDateObject
      if (
        dateRange.endDate
          .startOf("day")
          .isSameOrAfter(startDateObject.startOf("day")) &&
        dateRange.endDate
          .startOf("day")
          .isSameOrBefore(endDateObject.startOf("day"))
      ) {
        return true;
      }

      // dateRange.Start <= startDateObject AND dateRange.End >= endDateObject
      if (
        dateRange.startDate
          .startOf("day")
          .isSameOrBefore(startDateObject.startOf("day")) &&
        dateRange.endDate
          .startOf("day")
          .isSameOrAfter(endDateObject.startOf("day"))
      ) {
        return true;
      }
    }

    return false;
  }

  var itemPassesFilters = function (element) {
    var itemCategories = getDataGroupArray(element.getAttribute("data-groups"));
    var itemDateRangesString = element.getAttribute("data-dates");

    var validDateRanges = []; // valid date objects
    var dateRangeArray = [];
    var emptyArrayStringLength = 4; // an empty array string is [""] with at least 4 chars.
    var isValidDateRangeArrayString =
      itemDateRangesString &&
      itemDateRangesString.length >= emptyArrayStringLength;

    if (isValidDateRangeArrayString) {
      // i.e. date-ranges sample "["2020-06-26 00:00:00;2020-06-26 00:00:00", "2020-07-03 00:00:00;2020-07-03 00:00:00",""]
      var daterangeArrayString = itemDateRangesString.substring(
        2,
        itemDateRangesString.length - 2
      );
      if (daterangeArrayString.length) {
        dateRangeArray = daterangeArrayString.split(",");
        validDateRanges = getValidDateRanges(dateRangeArray);
      }
    }

    var itemPrimaryCategory = "all";

    // by default, filter primaryCategory sets to 'all', dateRange sets to 'anytime'
    var filterByPrimaryCategory = filters.primaryCategory;
    var filterByDateRange = filters.dateRange;

    if (itemCategories.length) {
      // Only primary category is populated
      //  it doesn't support sub categories
      itemPrimaryCategory = itemCategories[0];
    }

    // filter out by primary category
    if (
      filterByPrimaryCategory != "all" &&
      filterByPrimaryCategory != itemPrimaryCategory
    ) {
      return false;
    }

    //
    // filter out by date range
    if (filterByDateRange != "anytime" && validDateRanges.length <= 0) {
      return false;
    }

    // set the Moment objects
    const today = moment();

    const saturday = moment().day(6);
    const sunday = moment().day(7);

    const nextSevenDays = moment().add(7, "day");
    const nextThirtyDays = moment().add(30, "day");

    if (filterByDateRange == "today") {
      // today
      return checkEventAvailability(validDateRanges, today, today);
    }

    if (filterByDateRange == "thisweekend") {
      // this Sat => Sunday
      return checkEventAvailability(validDateRanges, saturday, sunday);
    }

    if (filterByDateRange == "nextsevendays") {
      // today (inclusive) => today + 7 days
      return checkEventAvailability(validDateRanges, today, nextSevenDays);
    }

    if (filterByDateRange == "nextthirtydays") {
      // today (inclusive) => today + 30 days
      return checkEventAvailability(validDateRanges, today, nextThirtyDays);
    }

    // return true if an item meets all filter requirement
    return true;
  };

  function dataGroupButtonsOnClick(shuffleInstance) {
    var options = document.querySelector(".filter-options");

    if (options) {
      var filterButtons = Array.from(options.children);

      filterButtons.forEach(function (button) {
        button.addEventListener("click", function (event) {
          var btn = event.currentTarget;
          var primaryCategory = btn.getAttribute("data-group");
          var isFilterByCurrent = btn.classList.contains("current");

          if (isFilterByCurrent == false) {
            for (var i = 0; i < filterButtons.length; i++) {
              filterButtons[i].classList.remove("current");
            }

            btn.classList.add("current");
            //start search by primary category
            var data = '';
              if(primaryCategory!=='all'){
                data = { TitleSearch: document.getElementById("filters-search-input").value, tag: primaryCategory, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value };
              }else{
                var tmpQuery = document.getElementById("filters-search-input").value;
                if(tmpQuery == ''){
                  tmpQuery = '!showall';
                }
                data = { TitleSearch: tmpQuery, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value };
              }
              var url = getSiteLocationPath() + "/search";
              var succeed = function (response) {
                if (response) {
                  var responseJson;
                  try {
                    responseJson = JSON.parse(response);
                  } catch (ex) {
                    responseJson = {
                      EventsItemCards: "<div></div>",
                      EventsItemCardsCount: 0,
                    };
                  }
                  document.getElementById("startRank").value = responseJson.NextStart;
                  document.getElementById("eventQuery").value = responseJson.Query;
                  document.getElementById("eventKeyword").value = responseJson.Keyword;
                  if(document.getElementById("startRank").value!='' && document.getElementById("startRank").value!='0'){
                    enableMoreEventsButton();
                  }else{
                    hideMoreEventsButton();
                  }
                  var htmlContent = document.createElement("div");
                  htmlContent.innerHTML = responseJson.EventsItemCards;

                  // store current elements in session variable
                  numOfEventCards = shuffleInstance.element.children.length;

                  // remove all existing events elements
                  if (numOfEventCards) {
                    shuffleInstance.remove(
                      Array.from(shuffleInstance.element.children)
                    );
                  }

                  // populate search results
                  // convert response to an array
                  var eventItemsWrapper = document.createElement("div");
                  eventItemsWrapper.innerHTML = htmlContent.innerHTML;

                  var elements = Array.from(eventItemsWrapper.children);
                  // toggle no record found message if no results found
                  if (elements.length == 0) {
                    // showNoRecordFoundDiv();
                    showNoRecordDiv(responseJson.Query, responseJson.Spell);
                  } else {
                    hideNoRecordFoundDiv();
                    showRecordDiv(responseJson.Start, responseJson.End, responseJson.Total, responseJson.Query);
                  }

                  elements.forEach(function (element) {
                    shuffleInstance.element.appendChild(element);
                  });

                  // Tell shuffle items have been appended.
                  // It expects an array of elements as the parameter.
                  Promise.resolve(shuffleInstance.add(elements)).then(function () {
                    shuffleInstance.update();
                  });
                } else {
                  // remove all existing events elements
                  if (shuffleInstance.element.children.length) {
                    shuffleInstance.remove(
                      Array.from(shuffleInstance.element.children)
                    );
                  }
                  showNoRecordFoundDiv();
                }
              };

              var failed = function (xhr) {
                console.error("Error: " + xhr.responseText);
              };

              var completed = function () {
              };

              ajaxPost(url, data, succeed, failed, completed);
              //end search by primary category
              // // Update compounded filter
              // filters.primaryCategory = primaryCategory;

              // // filter each item
              // shuffleInstance.filter(itemPassesFilters, SortOptions);

              // filteredCount = shuffleInstance.visibleItems;

              // if (filteredCount == 0) {
              //   showNoRecordFoundDiv();
              //   hideMoreEventsButton();
              // } else {
              //   hideNoRecordFoundDiv();
              // }
          }
        });
      }, this);
    }
  }

  function dateRangeSelectOnChange(shuffleInstance) { 
    var options = document.getElementById("dateRangeFilterOptions-prog");

    options.addEventListener(
      "change",
      function (event) {
        var selectedOption = event.target.value;

        // update compounded filter
        filters.dateRange = selectedOption;

        shuffleInstance.filter(itemPassesFilters, SortOptions);

        filteredCount = shuffleInstance.visibleItems;

        if (filteredCount == 0) {
          showNoRecordFoundDiv();
          hideMoreEventsButton();
        } else {
          hideNoRecordFoundDiv();
        }
      },
      false
    );
  }

  function toggleFilterButtons(uniqueDataGroups) {
    // toggle filter buttons from available data groups
    var globalGroup = document.querySelector(".filter-options").children;
    for (var i = 0; i < globalGroup.length; i++) {
      var element = globalGroup[i];

      // clear current status
      element.classList.remove("current");

      // clean up categories
      var elementDataGroup = element.getAttribute("data-group");

      if (
        elementDataGroup !== "all" &&
        uniqueDataGroups.indexOf(elementDataGroup) == -1
      ) {
        hideElement(element);
      } else {
        showElement(element, "block");
      }
    }
  }

  function getDataGroupArray(datagroupsString) {
    var datagroupsArray = [];
    var emptyArrayStringLength = 4; // an empty array string is [""] with at least 4 chars.
    var isValidDataGroupArrayString =
      datagroupsString && datagroupsString.length >= emptyArrayStringLength;

    if (isValidDataGroupArrayString) {
      // i.e. data-groups sample ["Community,Arts,Business,Carnivals & Fairs"]
      var datagroupArrayString = datagroupsString.substring(
        2,
        datagroupsString.length - 2
      );
      if (datagroupArrayString.length) {
        datagroupsArray = datagroupArrayString.split(",");
      }
    }

    return datagroupsArray;
  }

  function getUniqueDataGroups(shuffleInstance, elements) {
    var uniqueDataGroups = [];

    elements.forEach(function (element) {
      shuffleInstance.element.appendChild(element);

      var datagroupsString = element.getAttribute("data-groups");
      datagroupsArray = getDataGroupArray(datagroupsString);

      for (var i = 0; i < datagroupsArray.length; i++) {
        var dataGroupName = datagroupsArray[i];
        if (uniqueDataGroups.indexOf(dataGroupName) == -1) {
          uniqueDataGroups.push(dataGroupName);
        }
      }
    }, this);

    return uniqueDataGroups;
  }

  function ajaxGet(url, data, succeed, failed, completed) {
    var request = $.ajax({
      url: url,
      type: "GET",
      data: data,
      contentType: "application/json; charset=utf-8",
    });
    request.done(succeed).fail(failed).always(completed);
  }

  function ajaxPost(url, data, succeed, failed, completed) {
    var request = $.ajax({
      url: url,
      type: "POST",
      data: data
    });
    request.done(succeed).fail(failed).always(completed);
  }

  function searchFormOnSubmit(shuffleInstance) {
    // Overwrites form default submit action
    var searchForm = document.getElementById("simpleSearchForm");
    if (searchForm) {
      document
        .getElementById("simpleSearchForm")
        .addEventListener("submit", function (e) {
          e.preventDefault();
          $('.tt-menu').css('display', 'none');

          document.getElementById("search-suggestions-inner").style.display =
            "none";

          var searchSubmitButton = document.getElementById(
            "searchSubmitButton"
          );
          // searchSubmitButton.setAttribute("disabled", "disabled");

          // get search criteria
          var searchTextbox = document.querySelector(".filter__search");
          var criteria = searchTextbox.value;
          if (criteria.trim().length == 0) {
            searchTextbox.focus();
            return;
          }

          // toggle elements
          // showLoadingIndicator();
          // disableSearchButton();
          // hideMoreEventsButton();

          // showSearchCriteriaDiv();
          document.getElementById("filterByTitleWords").innerHTML = criteria;
          // search events by title search
          var url = getSiteLocationPath() + "/search";
          var data = { TitleSearch: criteria, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value, tag: $('button.btn.btn-link.current').text() };
          var succeed = function (response) {
            if (response) {
              var responseJson;

              try {
                responseJson = JSON.parse(response);
              } catch (ex) {
                responseJson = {
                  EventsItemCards: "<div></div>",
                  EventsItemCardsCount: 0,
                };
              }

              document.getElementById("startRank").value = responseJson.NextStart;
              document.getElementById("eventQuery").value = responseJson.Query;
              document.getElementById("eventKeyword").value = responseJson.Keyword;
              if(document.getElementById("startRank").value!='' && document.getElementById("startRank").value!='0'){
                enableMoreEventsButton();
              }else{
                hideMoreEventsButton();
              }
              var htmlContent = document.createElement("div");
              htmlContent.innerHTML = responseJson.EventsItemCards;

              //var resultsCount = responseJson.EventsItemCardsCount;
              //console.log(resultsCount);

              // store current elements in session variable
              numOfEventCards = shuffleInstance.element.children.length;

              // remove all existing events elements
              if (numOfEventCards) {
                shuffleInstance.remove(
                  Array.from(shuffleInstance.element.children)
                );
              }

              // populate search results

              // convert response to an array
              var eventItemsWrapper = document.createElement("div");
              eventItemsWrapper.innerHTML = htmlContent.innerHTML;

              var elements = Array.from(eventItemsWrapper.children);

              // toggle no record found message if no results found
              if (elements.length == 0) {
                // showNoRecordFoundDiv();
                showNoRecordDiv(responseJson.Query, responseJson.Spell);
              } else {
                hideNoRecordFoundDiv();
                showRecordDiv(responseJson.Start, responseJson.End, responseJson.Total, responseJson.Query);
              }

              elements.forEach(function (element) {
                shuffleInstance.element.appendChild(element);
              });

              // Tell shuffle items have been appended.
              // It expects an array of elements as the parameter.
              Promise.resolve(shuffleInstance.add(elements)).then(function () {
                shuffleInstance.update();
                clickAllCategoryButton();

                // eventPrimaryCategoryTagSearch();
                // typeOfEventTagSearch();

                // enableSearchButton();
                // hideLoadingIndicator();
              });

            } else {
              // remove all existing events elements
              if (shuffleInstance.element.children.length) {
                shuffleInstance.remove(
                  Array.from(shuffleInstance.element.children)
                );
              }
              showNoRecordFoundDiv();
              clickAllCategoryButton();

              // enableSearchButton();
              // hideLoadingIndicator();
            }
          };

          var failed = function (xhr) {
            console.error("Error: " + xhr.responseText);

            // enableSearchButton();
            // enableMoreEventsButton();
            // hideLoadingIndicator();
          };

          var completed = function () {
            // searchSubmitButton.removeAttribute("disabled");
            // isSearchCompleted = true;
          };

          ajaxPost(url, data, succeed, failed, completed);
        });
    }
  }

  function getSiteLocationPath() {
    var pathArray = window.location.pathname.split("/");
    var newPathname = "";
    for (i = 0; i < pathArray.length; i++) {
      if (pathArray[i].length) {
        newPathname += "/";
        newPathname += pathArray[i];
      }
    }

    return window.location.origin + newPathname;
  }

  function moreEventsButtonOnClick(shuffleInstance) {
    var moreEventsButton = document.getElementById(
      MORE_EVENTS_BUTTON_CLIENT_ID
    );
    if (moreEventsButton) {
      moreEventsButton.addEventListener("click", function () {
        showLoadingMoreEventsIndicator();
        disableMoreEventsButton();

        var url = getSiteLocationPath() + "/fetch";
        var data = { limit: 20, start_rank: document.getElementById("startRank").value, TitleSearch: document.getElementById("eventQuery").value, tag: document.getElementById("eventKeyword").value, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value, tag: $('button.btn.btn-link.current').text() };
        var succeed = function (response) {
          if (response) {
            var responseJson;

            try {
              responseJson = JSON.parse(response);
            } catch (ex) {
              responseJson = {
                EventsItemCards: "<div></div>",
                AllResultsCount: 0,
                CurrentFeaturedItemsCount: 0,
                HasMoreEvents: false,
                NextStart: 0,
                Start: 0,
                End: 0,
                Total: 0,
                Query: ''
              };
            }
            document.getElementById("startRank").value = responseJson.NextStart;
            document.getElementById("eventQuery").value = responseJson.Query;
            document.getElementById("eventKeyword").value = responseJson.Keyword;
            var htmlContent = document.createElement("div");
            htmlContent.innerHTML = responseJson.EventsItemCards;
            var hasMoreEvents = responseJson.HasMoreEvents;
            var eventItemsWrapper = document.createElement("div");
            eventItemsWrapper.innerHTML = htmlContent.innerHTML;
            var elements = Array.from(eventItemsWrapper.children);
            elements.forEach(function (element) {
              shuffleInstance.element.appendChild(element);
            }, this);

            showRecordDiv(responseJson.Start, responseJson.End, responseJson.Total, responseJson.Query);

            // Tell shuffle items have been appended.
            // It expects an array of elements as the parameter.
            Promise.resolve(shuffleInstance.add(elements)).then(function () {
              //shuffleInstance.update();
              eventPrimaryCategoryTagSearch();
              typeOfEventTagSearch();

              //re-enable more events button
              enableMoreEventsButton();

              // hide more events if there not more events to show
              if (!hasMoreEvents) {
                hideMoreEventsButton();
              }

              hideLoadingMoreEventsIndicator();
            });
          } else {
            hideMoreEventsButton();
            hideLoadingMoreEventsIndicator();
          }
        };

        var failed = function (xhr) {
          console.error("Error: " + xhr.responseText);

          hideLoadingMoreEventsIndicator();
          enableMoreEventsButton();
        };

        ajaxPost(url, data, succeed, failed, null);
      });
    }
  }

  // clear search filter
  function clearSearchCriteriaButtonOnClick(shuffleInstance) {
    document
      .getElementById(CLEAR_SEARCH_FILTER_BUTTON_CLIENT_ID)
      .addEventListener("click", function () {
        document.getElementById("filterBy").style.display = "none";
        disableClearFilterButton();
        showLoadingIndicator();
        hideNoRecordFoundDiv();

        // remove search results
        if (shuffleInstance.element.children.length) {
          shuffleInstance.remove(Array.from(shuffleInstance.element.children));
        }

        // show data groups
        var globalGroup = document.querySelector(".filter-options").children;
        for (var i = 0; i < globalGroup.length; i++) {
          var element = globalGroup[i];
          var elementDataGroup = element.getAttribute("data-group");
          if (elementDataGroup !== "all") {
            showElement(element);
          }
        }

        // fetch events
        var url = getSiteLocationPath() + "/fetch";
        var data = { limit: numOfEventCards, offset: 0, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value, tag: $('button.btn.btn-link.current').text() };
        var succeed = function (response) {
          if (response) {
            var responseJson;

            try {
              responseJson = JSON.parse(response);
            } catch (ex) {
              responseJson = {
                EventsItemCards: "<div></div>",
                AllResultsCount: 0,
                CurrentFeaturedItemsCount: 0,
                HasMoreEvents: false,
              };
            }

            var htmlContent = document.createElement("div");
            htmlContent.innerHTML = responseJson.EventsItemCards;

            //var totalCount = responseJson.AllResultsCount;
            //var accumulatedCount = responseJson.CurrentFeaturedItemsCount;
            var hasMoreEvents = responseJson.HasMoreEvents;
            //console.log(totalCount);
            //console.log(accumulatedCount);

            var eventItemsWrapper = document.createElement("div");
            eventItemsWrapper.innerHTML = htmlContent.innerHTML;

            var elements = Array.from(eventItemsWrapper.children);

            elements.forEach(function (element) {
              shuffleInstance.element.appendChild(element);
            }, this);

            // Tell shuffle items have been appended.
            // It expects an array of elements as the parameter.
            Promise.resolve(shuffleInstance.add(elements)).then(function () {
              //shuffleInstance.update();

              //re-enable more events button
              enableMoreEventsButton();

              // hide more events if there not more events to show
              if (!hasMoreEvents) {
                hideMoreEventsButton();
              }

              hideSearchCriteriaDiv();
              hideLoadingIndicator();

              eventPrimaryCategoryTagSearch();
              typeOfEventTagSearch();
            });
          } else {
            hideMoreEventsButton();
            hideLoadingMoreEventsIndicator();
          }
        };

        var failed = function (xhr) {
          console.error("Error: " + xhr.responseText);
          hideLoadingIndicator();
        };

        ajaxGet(url, data, succeed, failed, null);
      });
  }

  function highlightKeyWords(text, container) {
    var textLowerCase = text.trim().toLowerCase();
    var textLength = textLowerCase.length;

    var links = container.querySelectorAll("a");
    for (var i = 0; i < links.length; i++) {
      var link = links[i];
      var innerText = link.innerText;
      var innerTextLowerCase = innerText.toLowerCase();
      var foundIndex = innerTextLowerCase.indexOf(textLowerCase);

      if (foundIndex > -1) {
        link.innerHTML =
          innerText.substring(0, foundIndex) +
          "<span class='highlight'>" +
          innerText.substring(foundIndex, foundIndex + textLength) +
          "</span>" +
          innerText.substring(foundIndex + textLength);
      }
    }
  }

  function updateAutoComplete(responseHtml, container) {
    if (responseHtml.length > 0) {
      container.innerHTML = "<p>Suggestions:</p>" + responseHtml;
      container.style.display = "block";
    } else {
      searchAutocompleteInner.style.display = "none";
    }
  }

  var minCharsLimit = 3;
  var searchAutocompleteInner = document.getElementById(
    "search-suggestions-inner"
  );
  function searchAutocompleteOnChange() {
    var delaySearchAction = false;
    var timeout;
    var searchInput = document.getElementById("filters-search-input");
    if (searchInput) {


      searchInput.addEventListener(
        "keyup",
        function (e) {
          //
          // transaction scope
          isSearchCompleted = false;
          // reset the timer
          clearTimeout(timeout);

          // start timer
          timeout = setTimeout(function () {
            if (!delaySearchAction) {
              delaySearchAction = true;

              var criteria = e.target.value.trim();
              var criteriaLength = criteria.length;
              var autocomplete = e.target.getAttribute("autocomplete"); // on OR off

              var enableAutoComplete = criteriaLength >= minCharsLimit;
              if (!enableAutoComplete) {
                delaySearchAction = false;

                $("#search-suggestions-inner").hide();
                $("#search-suggestions-inner").html("");
              } else {
                // search data
                var url = getSiteLocationPath() + "/search";
                var data = {
                  TitleSearch: criteria,
                  sort: document.getElementById("eventSortby").value,
                  dFilter: document.getElementById("dateRangeFilterOption").value,
                  tag: $('button.btn.btn-link.current').text()
                };

                var succeed = function (response) {
                  if (response) {
                    updateAutoComplete(response, searchAutocompleteInner);
                    highlightKeyWords(criteria, searchAutocompleteInner);
                  }
                };

                var failed = function (xhr) {
                  console.error("Error: " + xhr.responseText);
                };

                var completed = function () {
                  delaySearchAction = false;
                  if (isSearchCompleted) {
                    $("#search-suggestions-inner").hide();
                  }
                };

                ajaxPost(url, data, succeed, failed, completed);
              }
            }
          }, 500);
        },
        false
      );

      searchInput.addEventListener(
        "focusout",
        function () {
          setTimeout(function () {
            $("#search-suggestions-inner").hide();
            $("#search-suggestions-inner").html("");
          }, 500);
        },
        false
      );
    }
  }

  function primaryCategoryTagResultFilter() {
    var filterLinks = document.getElementsByClassName("primary-category-title");
    var filterGroupButtons = document
      .querySelector(".filters-group")
      .querySelectorAll("button");

    for (var i = 0; i < filterLinks.length; i++) {
      var filter = filterLinks[i];
      // jump back at top of events result
      filter.href = getSiteLocationPath() + "#events_result";

      filter.addEventListener(
        "click",
        function (e) {
          e.preventDefault();

          for (var j = 0; j < filterGroupButtons.length; j++) {
            var filterButton = filterGroupButtons[j];

            if (
              e.currentTarget.textContent.toLowerCase() ==
              filterButton.getAttribute("data-group").toLowerCase()
            ) {
              filterButton.click();
              break;
            }
          }
        },
        false
      );
    }
  }

  function eventPrimaryCategoryTagSearch() {
    var searchLinks = document.getElementsByClassName("primary-category-title");
    for (var i = 0; i < searchLinks.length; i++) {
      var searchLink = searchLinks[i];

      searchLink.addEventListener("click", function (e) {
        e.preventDefault();
        searchLink.href = "#";

        searchEvents(decodeURIComponent(e.target.textContent));
      });
    }
  }

  function typeOfEventTagSearch() {
    var searchLinks = document.getElementsByClassName("event-type-title");
    for (var i = 0; i < searchLinks.length; i++) {
      var searchLink = searchLinks[i];

      searchLink.addEventListener("click", function (e) {
        e.preventDefault();
        searchLink.href = "#";

        searchEvents(decodeURIComponent(e.target.textContent));
      });
    }
  }

  function filterEventsResultOnLoad(primaryCategoryTitle) {
    var filterGroupButtons = document
      .querySelector(".filters-group")
      .querySelectorAll("button");

    for (var j = 0; j < filterGroupButtons.length; j++) {
      var filterButton = filterGroupButtons[j];

      if (
        primaryCategoryTitle.toLowerCase() ==
        filterButton.getAttribute("data-group").toLowerCase()
      ) {
        filterButton.click();
        break;
      }
    }
  }

  function searchEvents(queryText) {
    document.getElementById("filters-search-input").value = queryText;
    document.getElementById("filters-search-input").focus();
    document.getElementById("searchSubmitButton").click();
  }

  function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] == variable) {
        return pair[1];
      }
    }
    return false;
  }

  function addSearchBoxOnFocusEvent() {
    var searchTextBox = document.getElementById("filters-search-input");
    if (searchTextBox) {
      searchTextBox.addEventListener(
        "focus",
        function () {
          document
            .getElementById("searchSubmitButton")
            .classList.add("on-focus");
        },
        false
      );

      searchTextBox.addEventListener(
        "focusout",
        function () {
          document
            .getElementById("searchSubmitButton")
            .classList.remove("on-focus");
        },
        false
      );
    }
  }

  var filters = null;

  // Page onLoad
  document.addEventListener("DOMContentLoaded", function () {
    // Initiate shuffle to the event results grid

    var Shuffle = window.Shuffle;
    var eventResults = document.getElementById(EVENT_RESULTS_CLIENT_ID);

    if (eventResults) {

      shuffleInstance = new Shuffle(eventResults, {
        itemSelector: ".event-card",
        speed: 400,
      });

      // compounding filter by default
      filters = {
        primaryCategory: ["all"],
        dateRange: ["anytime"],
      };

      // Initiate onclick handlers to buttons

      dataGroupButtonsOnClick(shuffleInstance);
      
    if(document.getElementById("dateRangeFilterOptions-prog")){
      dateRangeSelectOnChange(shuffleInstance);
    }

      moreEventsButtonOnClick(shuffleInstance);

      // clearSearchCriteriaButtonOnClick(shuffleInstance);

      // Initiate search
      // searchAutocompleteOnChange();
      searchFormOnSubmit(shuffleInstance);
      // funnelbackSearch(shuffleInstance);

      eventPrimaryCategoryTagSearch();
      typeOfEventTagSearch();
      addSearchBoxOnFocusEvent();

      var queryText = getQueryVariable("query");
      if (queryText) {
        searchEvents(decodeURIComponent(queryText));
      }
    }
    else {
      showNoRecordFoundDiv();
    }
  });

  $('#spellSearch').on('click', function() {
    document.querySelector(".filter__search").value = $('#spellSearch > b').html();
    funnelbackSearch(shuffleInstance);
  });

  $('#searchSubmitButton').on('click', function() {
    funnelbackSearch(shuffleInstance);
  });
  
  $('#eventSortby').on('change', function() {
    funnelbackSearch(shuffleInstance);
  });

  $('#dateRangeFilterOption').on('change', function() {
    funnelbackSearch(shuffleInstance);
  });

  function funnelbackSearch(shuffleInstance){
    var url = getSiteLocationPath() + "/search";
    var data = { TitleSearch: document.querySelector(".filter__search").value, sort: document.getElementById("eventSortby").value, dFilter: document.getElementById("dateRangeFilterOption").value, tag: $('button.btn.btn-link.current').text()};
    var succeed = function (response) {
      if (response) {
        var responseJson;
        try {
          responseJson = JSON.parse(response);
        } catch (ex) {
            responseJson = {
            EventsItemCards: "<div></div>",
            EventsItemCardsCount: 0,
          };
        }
        document.getElementById("startRank").value = responseJson.NextStart;
        document.getElementById("eventQuery").value = responseJson.Query;
        document.getElementById("eventKeyword").value = responseJson.Keyword;
        var htmlContent = document.createElement("div");
        htmlContent.innerHTML = responseJson.EventsItemCards;

        // store current elements in session variable
        numOfEventCards = shuffleInstance.element.children.length;

        // remove all existing events elements
        if (numOfEventCards) {
          shuffleInstance.remove(
            Array.from(shuffleInstance.element.children)
          );
        }

        var eventItemsWrapper = document.createElement("div");
        eventItemsWrapper.innerHTML = htmlContent.innerHTML;

        var elements = Array.from(eventItemsWrapper.children);
        if (elements.length == 0) {
          showNoRecordDiv(responseJson.Query, responseJson.Spell);
        } else {
          hideNoRecordFoundDiv();
          showRecordDiv(responseJson.Start, responseJson.End, responseJson.Total, responseJson.Query);
        }
        
        elements.forEach(function (element) {
          shuffleInstance.element.appendChild(element);
        });

        Promise.resolve(shuffleInstance.add(elements)).then(function () {
          shuffleInstance.update();
        });

        if(document.getElementById("startRank").value!='' && document.getElementById("startRank").value!='0'){
          enableMoreEventsButton();
        }else{
          hideMoreEventsButton();
        }
      }else{
        if (shuffleInstance.element.children.length) {
          shuffleInstance.remove(
            Array.from(shuffleInstance.element.children)
          );
        }
        showNoRecordDiv(responseJson.Query, responseJson.Spell);
      }
    }

    var failed = function (xhr) {
      console.error("Error: " + xhr.responseText);
      showNoRecordDiv('', '');
    };

    var completed = function () {
      //no action
    };

    ajaxPost(url, data, succeed, failed, completed);
  }

  $('#simpleSearchForm').on('submit', function(e){
    $('.tt-menu').css('display', 'none');
    e.preventDefault();
    funnelbackSearch(shuffleInstance);
  });

  $('#filters-search-input').on('input', function () {
    var searchInput =  $('#filters-search-input').val();
    if(searchInput!=''){
        document.getElementById('btnClrWhatsonSearch').style.display = "inline";
    }else{
        document.getElementById('btnClrWhatsonSearch').style.display = "none";
    }
  });

  document.querySelector('#filters-search-input').addEventListener('keypress', function (e) {
		if (event.keyCode == 13 || event.keyCode == 9) {
      e.preventDefault();
      funnelbackSearch(shuffleInstance);
			return true;
		} else {
			return false;
		}
	});
})();